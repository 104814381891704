require("dotenv").config();

// http://54.254.225.5/
const URL =
	process.env.NODE_ENV === "development"
		? {
				//dev
				server: 'http://127.0.0.1:4004',
				rawData: process.env.REACT_APP_DEV_RAW_URL,
				// rawSearchData: `${process.env.REACT_APP_PRO_DOMAIN}/finddata`,
				xmlData: `https://locals.innovavietnam.com/xmldata`,//process.env.REACT_APP_DEV_XML_URL,
				rawApi: `http://127.0.0.1:4004/setting`
		  }
		: {
				server: process.env.REACT_APP_PRO_DOMAIN || 'https://locals.innovavietnam.com',
				rawData: `${process.env.REACT_APP_PRO_DOMAIN}/data` || `https://locals.innovavietnam.com/data`,
				// rawSearchData: `https://locals.innovavietnam.com/finddata`,
				xmlData: `${process.env.REACT_APP_PRO_DOMAIN}/xmldata` || `https://locals.innovavietnam.com/xmldata`,
				rawApi: `${process.env.REACT_APP_PRO_DOMAIN}/setting` ||  `https://locals.innovavietnam.com/setting`
		  };

module.exports = URL;
